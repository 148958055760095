// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nieuw-js": () => import("./../../../src/pages/nieuw.js" /* webpackChunkName: "component---src-pages-nieuw-js" */),
  "component---src-pages-old-js": () => import("./../../../src/pages/old.js" /* webpackChunkName: "component---src-pages-old-js" */)
}

